import * as _callBound2 from "call-bind/callBound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
var exports = {};
var callBound = _callBound;
var $byteLength = callBound("SharedArrayBuffer.prototype.byteLength", true);

/** @type {import('.')} */
exports = $byteLength ? function isSharedArrayBuffer(obj) {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  try {
    $byteLength(obj);
    return true;
  } catch (e) {
    return false;
  }
} : function isSharedArrayBuffer(obj) {
  // eslint-disable-line no-unused-vars
  return false;
};
export default exports;